import React from 'react';
import PropTypes from 'prop-types';
import { scrollTo } from '@firsttable/functions';
import Rating from '../Restaurant/Rating';
import Link from '../../atoms/Link';
import PriceRating from '../../molecules/PriceRating';
import Title from '../../atoms/Title';
import Rank from '../../molecules/Rank';
import Box from '../../atoms/Box/Box';
import Flex from '../../atoms/Flex/Flex';
import LabelNew from '../../atoms/LabelNew';
import RestaurantShare from '../Restaurant/Share';
import Breadcrumbs from '../../atoms/Breadcrumbs';

const RestaurantTitle = ({
  breadcrumbs,
  headerTitle,
  price,
  rank,
  rating,
  reviews,
  isNew,
  featuredText,
  NavLinkComponent,
  ...props
}) => (
  <>
    <Breadcrumbs itemScope itemType="http://schema.org/BreadcrumbList">
      {breadcrumbs.edges.map(({ node }, i) => (
        <li
          key={node.slug}
          itemScope
          itemProp="itemListElement"
          itemType="http://schema.org/ListItem"
        >
          <Link to={node.slug} NavComponent={NavLinkComponent} itemProp="item">
            <Box as="span" itemProp="name">
              {node.menuTitle}
            </Box>
          </Link>
          <meta itemProp="position" content={i + 1} />
        </li>
      ))}
    </Breadcrumbs>
    <Box
      alignItems="center"
      display={[null, 'flex']}
      flex={1}
      id="RestaurantTitle"
    >
      <Box flex={1} display={[null, null, 'flex']}>
        <Box flex={1}>
          <Flex mb={[0, 12]} alignItems="center">
            {rank !== 0 && (
              <Box m={['8px 8px 8px 0', '8px 8px 8px 0', '4px 8px 0 0']}>
                <Rank name="l-rank" rank={rank} />
              </Box>
            )}
            <Title as="h1" display="inline" mb="0px">
              {headerTitle}
            </Title>
            {isNew && (
              <LabelNew alignSelf={null} ml={['s', null, 24]} top={[0, -6]} />
            )}
            {featuredText && (
              <LabelNew
                alignSelf={null}
                ml={!isNew ? ['s', null, 24] : [5, null, 10]}
                top={[0, -6]}
              >
                {featuredText}
              </LabelNew>
            )}
          </Flex>
          <Rating
            position="relative"
            top="3px"
            mr="13px"
            display="inline-block"
            rating={rating}
            size="l"
          />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            underline
            onClick={() => scrollTo('RestaurantReviews')}
            color="grayscale.600"
          >
            {reviews}
            &nbsp;reviews
          </Link>
          {price && (
            <Box ml="l" display="inline">
              Price:&nbsp;&nbsp;
              <PriceRating display="inline-flex" rating={price} />
            </Box>
          )}
        </Box>
      </Box>
      <RestaurantShare {...props} ml="30px" display={['none', 'flex']} />
    </Box>
  </>
);

RestaurantTitle.propTypes = {
  headerTitle: PropTypes.string,
  breadcrumbs: PropTypes.object,
  price: PropTypes.number,
  rank: PropTypes.number,
  rating: PropTypes.number,
  reviews: PropTypes.number,
  isNew: PropTypes.bool,
  featuredText: PropTypes.string,
  NavLinkComponent: PropTypes.any.isRequired,
};
RestaurantTitle.defaultProps = {
  headerTitle: '',
  breadcrumbs: {},
  price: null,
  rank: null,
  rating: 0,
  reviews: 0,
  isNew: false,
  featuredText: null,
};

export default RestaurantTitle;
