import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import {
  awsImage,
  scrollTo,
  freeCreditAmount,
  productDetailEvent,
} from '@firsttable/functions';
import {
  Box,
  Button,
  Col,
  Container,
  Divider,
  Row, Text,
  Title,
} from '@firsttable/web-components/atoms';
import RestaurantTitle from '@firsttable/web-components/organisms/RestaurantTitle';
import styled from 'styled-components';
import gql from 'graphql-tag';
import Review from '@firsttable/web-components/organisms/Review';
import RestaurantReviews from '@firsttable/web-components/organisms/RestaurantReviews';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useAlert } from 'react-alert';
import Cookies from 'js-cookie';
import BirdAlert from '@firsttable/web-components/organisms/BirdAlert';
import PureRestaurantHero from '@firsttable/web-components/organisms/RestaurantHero/PureRestaurantHero';
import { fireBaseRemoteConfig } from '../helpers/firebase';
import { useFirebase, useAlertState } from '../hooks';
import { dataLayer, ecomLayer, fbq } from '../helpers/events';
import ThemeLayout from './layout';
import Footer from '../components/organisms/Footer';
import SEO from '../components/seo';
import Navigation from '../components/organisms/Navigation/Menu';
// eslint-disable-next-line import/no-named-as-default
import RestaurantAvailability from '../components/organisms/RestaurantAvailability/RestaurantAvailability';
import RestaurantOthers from '../components/organisms/RestaurantOther';
import usePagination from '../hooks/pagination';
import LoadingLayout from './LoadingLayout';
import RestaurantInfo from '../components/organisms/RestaurantInfo';
import withLocation from '../hocs/location';
import AuthContext from '../context/AuthContext';
import useRestaurantGalleryLightBox from '../hooks/useRestaurantGalleryLightBox';
import ModalContext from '../context/ModalContext';
import SignUp from '../components/organisms/Auth/SignUp';
import NavLinkComponent from '../components/atoms/NavLink';
import Logger from '../helpers/logger';
import {breakpointsObject as bp} from "@firsttable/web-theme";
import {themeGet} from "@styled-system/theme-get";

const logger = new Logger('Alert State');

const Sticky = styled(Box)`
  position: -webkit-sticky;
  position: sticky;
`;

const FloatingSideBarWrapper = styled(Box)`
  @media screen and (min-width: ${bp.m}) {
    ${themeGet('colorStyles.card.m')};
  }
`;

const REVIEW_QUERY = gql`
  query RestaurantPaginatedReviewQuery(
    $restaurantId: Int
    $favourite: Boolean
    $limit: Int
    $offset: Int
  ) {
    data: paginatedReviews(
      restaurantId: $restaurantId
      favourite: $favourite
      limit: $limit
      offset: $offset
    ) {
      edges {
        node {
          id
          date
          name
          comment
          foodRating
          serviceRating
          userAvatar
          favourite
          replies {
            id
            name
            date
            userType
            comment
          }
          restaurant {
            id
            title
            gallery
            logo {
              url
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalCount
      }
    }
  }
`;

const PaginatedReview = ({ data }) => (
  <Box mb={['l', 'xl']}>
    <Review review={data} showRatings={false} mb={['m', 'l']} />
    <Divider />
  </Box>
);

PaginatedReview.propTypes = {
  data: PropTypes.shape().isRequired,
};

const RestaurantReviewTabs = ({ restaurant }) => {
  const perPage = isMobile ? 5 : 10;
  const AllPagination = usePagination(PaginatedReview, REVIEW_QUERY, perPage, {
    variables: {
      restaurantId: restaurant.foreignId,
    },
  });
  // For now feature reviews are commented out. Uncomment below and the render to restore
  // const FeaturedPagination = usePagination(PaginatedReview,
  //   REVIEW_QUERY, perPage, {
  //     variables: {
  //       restaurantId: restaurant.foreignId,
  //       favourite: true,
  //     },
  //   });

  // const showFeaturedReviews = restaurant.featuredReviewsCount !== null
  //   && restaurant.featuredReviewsCount > 0;
  // for now hide featured reviews and only show all reviews
  const showFeaturedReviews = false;

  if (!showFeaturedReviews) {
    return <AllPagination id="RestaurantReviews" key="All Reviews" />;
  }
  return null;
  // return (
  //   <Tabs id="RestaurantReviews" tabs={['Featured Reviews', 'All Reviews']}>
  //     <FeaturedPagination key="Featured Reviews" />
  //     <AllPagination key="All Reviews" />
  //   </Tabs>
  // );
};

RestaurantReviewTabs.propTypes = {
  restaurant: PropTypes.shape().isRequired,
};

const FEATURED_REVIEW_QUERY = gql`
  query getFeaturedReview($id: Int!) {
    Review(id: $id) {
      id
      date
      name
      comment
      foodRating
      serviceRating
      userAvatar
      favourite
      restaurant {
        id
        title
      }
    }
  }
`;

const RestaurantBody = ({ restaurant, review }) => {
  if (review) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { loading, data } = useQuery(FEATURED_REVIEW_QUERY, {
      variables: { id: review },
      fetchPolicy: 'network-only',
      ssr: false,
    });
    if (loading) {
      return <LoadingLayout />;
    }
    if (data) {
      return (
        <>
          <Title mb="25px">Review</Title>
          <Review review={data.Review} />
        </>
      );
    }
  }

  return (
    <>
      <RestaurantInfo restaurant={restaurant} />
      <Box display={[null, null, 'none']}>
        <RestaurantAvailability restaurant={restaurant} />
        <Divider mt="xl" mb="l" />
      </Box>
      <RestaurantReviews restaurant={restaurant} id="RestaurantReviews" />
      <RestaurantReviewTabs restaurant={restaurant} />
    </>
  );
};

RestaurantBody.propTypes = {
  review: PropTypes.number,
  restaurant: PropTypes.shape(),
};

const RESTAURANT_QUERY = gql`
  query getRestaurantUpdatedData($id: Int!) {
    Restaurant(id: $id) {
      status
      onHoldText
      graphQLData
    }
  }
`;

const RestaurantAvailabilityTitle = (restaurantStatus, restaurant) => {
  const { onHoldText, status } = restaurantStatus;
  const { title, breadcrumbs } = restaurant;
  if (
    status === 'Cancelled' ||
    (status === 'On Hold' && onHoldText && onHoldText.length > 0) ||
    status === 'Closed'
  ) {
    const { menuTitle, slug } = breadcrumbs.edges[0].node;
    const message = () => {
      let text;
      switch (status) {
        case 'Cancelled':
          text = 'Currently on a break from First Table';
          break;
        case 'Closed':
          text = `Unfortunately, ${title} is closed for business`;
          break;
        default:
          text = onHoldText;
      }
      return text;
    };

    return (
      <>
        <Title size="h4" mb="s">
          {title}
        </Title>
        <Text color="gold.900" fontSize="l" fontWeight="bold" mb="l">
          {message()}
        </Text>
        <Box textAlign="center">
          <Button to={slug} NavComponent={NavLinkComponent}>
            Go to {menuTitle}
          </Button>
        </Box>
      </>
    );
  }
  return (
    <>
      <Title size="h4" mb="xs">
        Book a First Table
      </Title>
      <Text color="gold.900" fontSize="s">
        <strong>Get 50% off the food bill for two to four people</strong>
      </Text>
    </>
  );
};

const RestaurantLayout = ({
  restaurant,
  regionRestaurants,
  region,
  siteConfig,
  city,
  location,
  search,
}) => {
  const { hash } = location;
  const { isLoggedIn } = useContext(AuthContext);
  const { showModalFunc } = useContext(ModalContext);
  const { alertFired, setAlertState } = useAlertState();
  const { firebaseIsLoaded, firebaseData } = useFirebase();
  const alertPop = useAlert();
  const { showRestaurantLightBox } = useRestaurantGalleryLightBox(restaurant);

  let restaurantData = restaurant;

  const { loading, error, data } = useQuery(RESTAURANT_QUERY, {
    variables: { id: restaurant.foreignId },
    fetchPolicy: 'network-only',
    ssr: false,
  });
  let restaurantStatus = {
    status: restaurant.status,
    onHoldText: restaurant.onHoldText,
  };
  // update restaurant data with fetched update
  if (!loading && !error && data?.Restaurant?.graphQLData) {
    restaurantStatus = {
      status: data.Restaurant.status,
      onHoldText: data.Restaurant.onHoldText,
    };
    restaurantData = Object.assign(
      restaurantData,
      JSON.parse(data.Restaurant.graphQLData),
    );
  }
  useEffect(() => {
    dataLayer.push({
      google_tag_params: {
        local_id: restaurant.foreignId,
        local_dealid: restaurant.foreignId,
        local_pagetype: 'offerdetail',
      },
    });


    fbq('track', 'ViewContent', {
      content_name: restaurant.menuTitle,
      content_ids: [restaurant.foreignId],
      content_type: 'product',
    });

    if (hash === '#reviews') {
      scrollTo('RestaurantReviews');
    }
  }, []);

  useEffect(() => {
    if (data) {
      productDetailEvent(restaurant);
    }
  },[data]);

  useEffect(() => {
    if (firebaseIsLoaded) {
      fireBaseRemoteConfig(
        { name: 'signup_bird', type: 'string' },
        firebaseData,
      )
        .then((event) => {
          const freeCredit = freeCreditAmount(siteConfig, false);
          const hasBirdPopUp = Cookies.get('BirdPopUp') === 'true';
          const pastLoggedIn = Cookies.get('PastLoggedIn') === 'true';
          const userLoggedIn = isLoggedIn();
          logger.debug(
            event,
            !alertFired,
            !userLoggedIn,
            !pastLoggedIn,
            !hasBirdPopUp,
          );
          if (
            event &&
            !alertFired &&
            !userLoggedIn &&
            !pastLoggedIn &&
            !hasBirdPopUp
          ) {
            const birdAlert = alertPop.show(
              <BirdAlert
                item={{ title: `${freeCredit} free credit` }}
                subTitle={`Create your free account and you'll get ${freeCredit} free credit to use towards your first booking.`}
                birdColor={event}
                event={() => {
                  dataLayer.push({
                    event: 'trackEvent',
                    eventCategory: 'Bird sign up',
                    eventAction: `Sign up bird clicked - ${event}`,
                  });
                  showModalFunc(
                    <SignUp
                      isModal
                      inviteCode="MRBIRDSIGNUP"
                      GDPRCountry={false}
                    />,
                    {
                      type: 'signUp',
                    },
                  );
                }}
              />,
              {
                timeout: 0,
                type: 'bird',
                position: 'bottom left',
                containerStyle: {
                  style: isMobile
                    ? {
                        margin: 0,
                        position: 'relative',
                        left: '5px',
                        bottom: '47px',
                      }
                    : { margin: 0, position: 'relative', left: '100px' },
                },
                closeIconStyle: {
                  style: isMobileOnly
                    ? { top: '12px', right: '25px', zIndex: '9999' }
                    : { top: '34px', right: '55px', zIndex: '9999' },
                },
                close: () => {
                  Cookies.set('BirdPopUp', true, { expires: 7 }); // expires in 7 days
                  alertPop.remove(birdAlert);
                },
              },
            );
            setAlertState(true);
          }
        })
        .catch(() => setAlertState(true));
    }
    return () => {
      alertPop.removeAll();
      setAlertState(false);
    };
  }, [firebaseIsLoaded]);

  return (
    <ThemeLayout footer={<Footer />}>
      <SEO
        id={restaurant.foreignId}
        title={restaurant.metaTitleFormatted}
        description={restaurant.metaDescription}
        ogImage={restaurant.ogImage}
        metaTags={restaurant.metaTags}
        slug={restaurant.slug}
      />
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Restaurant",
              "name": "${restaurant.menuTitle}",
              "image": [${restaurant.allImages.edges.map(
                ({ node }) => `"${awsImage(node.url, 1170, 655)}"`,
              )}],
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "${restaurant.street}",
                  "addressLocality": "${restaurant.city}",
                  "addressRegion": "${restaurant.city}",
                  "postalCode": "${restaurant.zip}",
                  "addressCountry": "${siteConfig.country}"
                },
                "geo": {
                  "@type": "GeoCoordinates",
                  "latitude": "${restaurant.lat}",
                  "longitude": "${restaurant.lng}"
                },
                "telephone": "${restaurant.phone}",
                "url": "${restaurant.website}",
                "potentialAction": {
                  "@type": "ReserveAction",
                  "target": {
                    "@type": "EntryPoint",
                    "url": "${restaurant.website}",
                    "inLanguage": "${siteConfig.language}",
                    "actionPlatform": [
                      "http://schema.org/DesktopWebPlatform",
                      "http://schema.org/IOSPlatform",
                      "http://schema.org/AndroidPlatform"
                    ]
                  },
                  "result": {
                    "@type": "FoodEstablishmentReservation",
                    "name": "Book a First Table"
                  }
                },
                "servesCuisine": "${restaurant.cuisines.edges.map(
                  ({ node }) => node.label,
                )}",
                "priceRange": "Mains: ${restaurant.mainsPriceRange}"${
            restaurant.ratingOutOfTen ? ',' : ''
          }
                ${
                  restaurant.ratingOutOfTen
                    ? `
                "aggregateRating": {
                  "@type": "AggregateRating",
                  "ratingValue": "${restaurant.ratingOutOfTen}",
                  "worstRating": "1",
                  "bestRating": "10",
                  "ratingCount": "${restaurant.reviewsCount}"
                }`
                    : ''
                }
            }
          `}
        </script>
      </Helmet>
      <Box mb={['20px', '48px']}>
        <Navigation menuTitle={city?.menuTitle} />
      </Box>
      <Container mb={['16px', '30px']} data-testid="restaurant-page">
        <RestaurantTitle
          {...restaurantData}
          NavLinkComponent={NavLinkComponent}
          breadcrumbs={restaurantData.breadcrumbs}
          headerTitle={restaurantData.title}
          rank={restaurantData.regionRanking || restaurantData.cityRanking}
          reviews={restaurantData.reviewsCount}
        />
      </Container>
      <Container
        maxWidth={1266}
        mb={['20px', '40px']}
        pl={[0, 'm']}
        pr={[0, 'm']}
      >
        <PureRestaurantHero
          handleRestaurantLightBox={showRestaurantLightBox}
          restaurantImages={restaurantData.allImages.edges}
          facebookVideoURL={restaurantData.facebookVideoURL}
        />
      </Container>
      <Container overflow={['hidden', 'visible']} mb={[20, null, 40]}>
        <Row gap={48}>
          <Col mb="50px" width={[1, 1, 'auto']}>
            <RestaurantBody
              review={search.review && +search.review}
              restaurant={restaurantData}
            />
          </Col>
          <Col width={[1, 1, 'contains']} display={['none', null, 'block']}>
            <Sticky
              top={0}
              pb={[20, null, 50]}
              mt={[null, null, '-135px']}
              maxWidth={450}
              minWidth={450}
            >
              <FloatingSideBarWrapper p={[null, null, '44px 50px 50px']}>
                <RestaurantAvailabilityTitle
                  restaurantStatus={restaurantStatus}
                  restaurant={restaurantData}
                  NavLinkComponent={NavLinkComponent}
                />
                <RestaurantAvailability
                  restaurantStatus={restaurantStatus}
                  restaurant={restaurantData}
                />
              </FloatingSideBarWrapper>
            </Sticky>
          </Col>
        </Row>
      </Container>
      <RestaurantOthers
        NavComponent={NavLinkComponent}
        restaurants={regionRestaurants}
        availabilitySession="dinner"
        region={region}
        my={[20, null, 40]}
        city={city}
        sp={20}
      />
      {restaurantData.status === 'Live' && (
        <Sticky
          display={[null, null, 'none']}
          zIndex={4}
          bottom={0}
          bg="white"
          p="s"
          boxShadow="0 0 30px rgba(0, 0, 0, 0.1)"
        >
          <Button
            wide
            kind="cta"
            onClick={() => scrollTo('RestaurantAvailability')}
            subtext="Get 50% off the food bill for two to four diners!"
          >
            Book a First Table
          </Button>
        </Sticky>
      )}
    </ThemeLayout>
  );
};

RestaurantLayout.propTypes = {
  restaurant: PropTypes.shape(),
  regionRestaurants: PropTypes.shape(),
  region: PropTypes.shape(),
  city: PropTypes.shape(),
  siteConfig: PropTypes.shape(),
  location: PropTypes.shape(),
  search: PropTypes.shape(),
};
RestaurantLayout.defaultProps = {};

export default withLocation(RestaurantLayout);
