import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@firsttable/web-components/atoms';
import ContactCTA from '@firsttable/web-components/molecules/ContactCTA';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import TwoColumnLayout from '../layouts/TwoColumnLayout';
import withLocation from '../hocs/location';
import LoadingLayout from '../layouts/LoadingLayout';
import RestaurantLayout from '../layouts/RestaurantLayout';
import redirects from '../../build/_redirects.json';
import NavButton from '../components/atoms/NavButton';

const GET_RESTAURANT_PREVIEW = gql`
  query getRestaurantPreviewNotFound($slug: String!) {
    restaurant: Restaurant(slug: $slug) {
      graphQLData
    }
  }
`;

const Content = () => (
  <>
    <h3>Sorry, it seems you were trying to access a page that doesn&lsquo;t exist.</h3>
    <Text>
      Please check the spelling of the URL you were trying to access and try again.
      {' '}
      Alternatively, try searching for a restaurant at the top of the page.
    </Text>
  </>
);

export const NotFoundLayout = () => (
  <TwoColumnLayout
    title="Page not found"
    col1={<Content />}
    col2={<ContactCTA mt="40px" NavButtonComponent={NavButton} />}
  />
);

const NotFoundPage = ({ location: { pathname } }) => {
  useEffect(() => {
    redirects.map(async ({ source, destination }) => {
      const regex = new RegExp(source);
      // handle client side redirect
      if (regex.test(pathname) || regex.test(pathname.replace(/\/$/, ''))) {
        await navigate(destination);
      }
    });
  }, []);
  const segments = pathname.split('/').filter((n) => n);
  const slug = segments[segments.length - 1];
  const regionRestaurants = null;
  const region = null;
  const { siteConfig } = useStaticQuery(graphql`
    query dynamicRestaurantQueryNotFound {
      siteConfig {
        country
        language
      }
    }
  `);

  const { data, error, loading } = useQuery(GET_RESTAURANT_PREVIEW, {
    variables: {
      slug,
    },
    ssr: false,
    fetchPolicy: 'no-cache',
  });
  if (loading) {
    return (
      <LoadingLayout showFooter showMenu />
    );
  }
  if (error) {
    return (
      <NotFoundLayout />
    );
  }
  const { restaurant } = data;
  if (restaurant) {
    const restaurantData = JSON.parse(restaurant.graphQLData);
    restaurantData.foreignId = restaurantData.id;
    return (
      <RestaurantLayout
        restaurant={restaurantData}
        regionRestaurants={regionRestaurants}
        siteConfig={siteConfig}
        region={region}
      />
    );
  }
  return (
    <NotFoundLayout />
  );
};

NotFoundPage.propTypes = {
  location: PropTypes.shape(),
};
export default withLocation(NotFoundPage);
