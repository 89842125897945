import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Photoswipe from 'photoswipe';
import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default';
import Markup from './Markup';
import events from './events';
// @todo next-web: css import
// import './scss/main.scss';
// import './scss/default-skin/default-skin.scss';

class PhotoSwipe extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    options: PropTypes.object,
    onClose: PropTypes.func,
    onChange: PropTypes.func,
    index: PropTypes.number,
  };

  static defaultProps = {
    options: {
      closeElClasses: [],
      fullscreenEl: false,
      shareEl: false,
      history: false,
      zoomEl: false,
      closeOnScroll: false,
      clickToCloseNonZoomable: false,
      timeToIdle: 2000,
      closeOnVerticalDrag: false,
      barsSize: {
        top: 10,
        bottom: 100, // thumbs
      },
    },
    onClose: () => {},
  };

  constructor(props) {
    super(props);
    this.photoSwipe = null;
    this.pswpElement = React.createRef();
    this.state = {
      thumbsInited: false,
    };
  }

  componentDidMount = () => {
    const { isOpen } = this.props;
    if (isOpen) {
      this.openPhotoSwipe(this.props);
    }
  };

  componentDidUpdate(prevProps) {
    const { props } = this;
    const { index } = props;
    const { isOpen: prevIsOpen } = prevProps;

    if (props.isOpen !== prevIsOpen) {
      // eslint-disable-next-line
      prevIsOpen ? this.closePhotoSwipe() : this.openPhotoSwipe(props);
    }
    if (props.index !== prevProps.index) {
      this.photoSwipe.goTo(index);
    }
  }

  componentWillUnmount = () => {
    this.closePhotoSwipe();
  };

  openPhotoSwipe = (props) => {
    const { items, options, index } = props;
    this.photoSwipe = new Photoswipe(
      this.pswpElement.current,
      PhotoswipeUIDefault,
      items,
      ({ ...options, index }),
    );
    events.forEach((event) => {
      const callback = props[event];
      if (callback || event === 'destroy') {
        const self = this;
        this.photoSwipe.listen(event, function (...args) {
          if (callback) {
            args.unshift(this);
            callback(...args);
          }
          if (event === 'destroy') {
            self.handleClose();
          }
        });
      }
    });
    this.photoSwipe.init();
    this.photoSwipe.listen('afterChange', () => {
      const { onChange } = this.props;
      onChange(this.photoSwipe.getCurrentIndex());
    });
    this.setState({
      thumbsInited: true,
    });
  };

  closePhotoSwipe = () => {
    if (!this.photoSwipe) return;
    this.photoSwipe.close();
    this.goTo(0);
  };

  handleClose = () => {
    const { onClose } = this.props;
    if (onClose) onClose();
  };

  goTo = (index) => {
    const { onChange } = this.props;
    onChange(index);
  };

  render() {
    const { thumbsInited } = this.state;

    return (
      <div
        ref={this.pswpElement}
        className="pswp"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <Markup {...this.props} goTo={this.goTo} init={thumbsInited} />
      </div>
    );
  }
}

export default PhotoSwipe;
