import React from 'react';
import PropTypes from 'prop-types';
import ContainerContent from '@firsttable/web-components/molecules/ContainerContent';
import { Row, Col } from '@firsttable/web-components/atoms';
import RowHead from '@firsttable/web-components/molecules/RowHead';
import Footer from '../components/organisms/Footer';
import MainMenu from '../components/organisms/Navigation/Menu';
import ThemeLayout from './layout';

const TwoColumnLayout = ({ title, subTitle, col1, col2, children }) => (
  <ThemeLayout footer={<Footer />}>
    <MainMenu />
    <ContainerContent>
      <RowHead title={title} subTitle={subTitle} />
      <Row gap={164}>
        <Col width={[1, 1 / 2]}>{col1}</Col>
        <Col width={[1, 1 / 2]} line>
          {col2}
        </Col>
      </Row>
      {children}
    </ContainerContent>
  </ThemeLayout>
);

TwoColumnLayout.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  col1: PropTypes.any,
  col2: PropTypes.any,
  children: PropTypes.any,
};
TwoColumnLayout.defaultProps = {
  title: '',
  subTitle: '',
  col1: null,
  col2: null,
};

export default TwoColumnLayout;
