import React from 'react';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import PureRestaurantAvailability from '@firsttable/web-components/organisms/RestaurantAvailability';
import AvailabilitySessionButton from '@firsttable/web-components/molecules/AvailabilitySessionButton';
import { isBrowser } from '@firsttable/functions';
import { useAvailabilitySession, use9PMRefresh } from '@firsttable/react-hooks';
import withFirebase from '../../../hocs/withFireBaseAvailability';
import withBooking from '../../../hocs/booking';
import NavLink from '../../atoms/NavLink';

export const AvailabilitySessionBtn = (props) => {
  const { session, setSession } = useAvailabilitySession();
  return (
    <AvailabilitySessionButton
      {...props}
      session={session}
      setSession={setSession}
    />
  );
};

AvailabilitySessionBtn.propTypes = {
  label: PropTypes.string,
};

export const RestaurantAvailability = (props) => {
  use9PMRefresh();
  return (
    <PureRestaurantAvailability
      AvailabilitySessionBtn={AvailabilitySessionBtn}
      NavButtonComponent={NavLink}
      {...props}
    />
  );
};

RestaurantAvailability.propTypes = {
  restaurant: PropTypes.object.isRequired,
};

const ConnectedRestaurantAvailability = compose(
  withBooking,
  withFirebase,
)(RestaurantAvailability);

const RestaurantAvailabilityWithState = ({ restaurant, ...props }) => {
  const { session, setSession } = useAvailabilitySession();
  const [firstSession] = restaurant.availabilitySessions;
  // if session doesn't exist in availabilitySessions, set to the first one
  if (
    isBrowser &&
    !restaurant.availabilitySessions.includes(session) &&
    firstSession
  ) {
    setSession(firstSession);
  }
  return (
    <ConnectedRestaurantAvailability
      availabilitySession={session}
      setSession={setSession}
      restaurant={restaurant}
      showAllAvailability
      allowFiltering={false}
      {...props}
    />
  );
};

RestaurantAvailabilityWithState.propTypes = {
  restaurant: PropTypes.shape(),
};

export default RestaurantAvailabilityWithState;
