import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import Box from '../../atoms/Box';

const Wrapper = styled(Box)`
  max-width: 100%;
  overflow: hidden;
`;

const ImgOverlay = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`;

const ImgLength = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding-right: 10px;
  font-family: ${themeGet('fonts.headings')};
`;

const ThumbnailImage = styled(Box)`
  &::after {
    content: '';
    display: block;
    padding-bottom: 82.47%;
    animation: bgPulse 1s ease-out infinite;
  }

  &:last-child {
    margin-right: 0;
  }

  .lazyload-img-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img {
    max-width: 100%;
  }
`;

const ImgBox = styled(Box)`
  cursor: pointer;
`;

const MobileImageWrap = styled(Box)`
  position: relative;

  .lazyload-img-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &::after {
    content: '';
    display: block;
    padding-bottom: 61.84896%;
    animation: bgPulse 1s ease-out infinite;
  }
`;
const MobileVideoWrap = styled(Box)`
  position: relative;
`;

export {
  Wrapper,
  ImgOverlay,
  ImgLength,
  ThumbnailImage,
  ImgBox,
  MobileImageWrap,
  MobileVideoWrap,
};
