import React from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import PropTypes from 'prop-types';
import spaces from '@firsttable/web-theme/styledSpaces';
import Box from '../../../atoms/Box';
import Icon from '../../../atoms/Icon';
import Flex from '../../../atoms/Flex';
import Link from '../../../atoms/Link';
import {
  formatFacebookUrl,
  formatInstagramUrl, formatUrl,
} from '@firsttable/functions/src/functions';

const StyledShare = styled(Flex)`
  .icon path,
  .icon rect,
  .icon circle {
    transition: 0.075s ease;
  }
  .icon:hover {
    circle {
      fill: ${themeGet('colors.grayscale.300')};
    }
    rect,
    path {
      fill: ${themeGet('colors.brand.blue')};
    }
  }
`;

const Share = (props) => {
  const { display, instagram, facebook, website } = props;
  return (
    <StyledShare display={display} {...spaces(props)}>
      {instagram && (
        <Box ml="xs">
          <Link href={formatInstagramUrl(instagram)}>
            <Icon role="button" className="icon" name="l-instagram" />
          </Link>
        </Box>
      )}
      {facebook && (
        <Box ml="xs">
          <Link href={formatFacebookUrl(facebook)}>
            <Icon role="button" className="icon" name="l-facebook" />
          </Link>
        </Box>
      )}
      {website && (
        <Box ml="xs">
          <Link href={formatUrl(website)}>
            <Icon role="button" className="icon" name="l-website" />
          </Link>
        </Box>
      )}
    </StyledShare>
  );
};

Share.propTypes = {
  display: PropTypes.array,
  instagram: PropTypes.string,
  facebook: PropTypes.string,
  website: PropTypes.string,
};

Share.defaultProps = {
  display: [],
  instagram: '',
  facebook: '',
  website: '',
};

export default Share;
