import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import BirdBlue from './Bird_blue.svg';
import BirdPink from './Bird_pink.svg';
import BirdGreen from './Bird_green.svg';

import { Box, Text, Link } from '../../atoms';

const birds = {
  blue: BirdBlue,
  pink: BirdPink,
  green: BirdGreen,
};

const CardLink = ({ item, children }) =>
  item.slug ? (
    <Link to={item.slug}>{children}</Link>
  ) : (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={item.href} target="_blank">
      {children}
    </a>
  );

CardLink.propTypes = {
  item: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
};

const BirdAlert = ({ item, subTitle, event, birdColor }) => (
  <Box
    style={
      isMobileOnly
        ? { position: 'relative', width: '200px', cursor: 'pointer' }
        : { position: 'relative', width: '500px', cursor: 'pointer' }
    }
    onClick={event}
  >
    <Box
      style={
        isMobileOnly
          ? {
              position: 'absolute',
              zIndex: 10,
              top: '10px',
              left: '60px',
            }
          : {
              position: 'absolute',
              zIndex: 10,
              left: '145px',
              top: '25px',
              right: '24px',
            }
      }
    >
      <Text as="h3" m="0" textAlign="center">
        {item.title}
      </Text>
      {!isMobileOnly && <Text textAlign="center">{subTitle}</Text>}
    </Box>
    <img
      src={get(birds, birdColor) || BirdBlue}
      alt="Bird"
      style={{ width: '100%' }}
    />
  </Box>
);

BirdAlert.propTypes = {
  item: PropTypes.shape().isRequired,
  subTitle: PropTypes.string,
  birdColor: PropTypes.string,
  event: PropTypes.func,
};
BirdAlert.defaultProps = {
  subTitle: null,
  birdColor: 'blue',
  event: () => {},
};

export default BirdAlert;
