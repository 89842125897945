import React, {
  useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { scroller } from 'react-scroll';
import queryString from 'query-string';
import { Icon, Box } from '@firsttable/web-components/atoms';
import { range } from '@firsttable/functions';
import { useQuery } from '@apollo/client';

const size = 40;
const spaceBetween = 3;

const PaginationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: ${themeGet('fontSizes.s')}px;
`;
const PaginationBtn = styled.div`
  width: ${size}px;
  line-height: ${size}px;
  margin: 0 ${spaceBetween}px;
  text-align: center;
  border-radius: 50%;
  background-color: ${({ currentPage }) => currentPage && themeGet('colors.grayscale.300')};
  transition: 0.1s ease background-color;

  &:hover {
    background: ${themeGet('colors.grayscale.300')};
  }
`;
const PaginationBtnPrevNext = styled.div`
  width: ${size}px;
  height: ${size}px;
  margin: 0 ${spaceBetween}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${themeGet('colors.brand.blue')};
  border-radius: 50%;
`;
const Dots = styled.div`
  line-height: ${size}px;
  margin: 0 ${spaceBetween}px;
`;

// https://gist.github.com/kottenator/9d936eb3e4e3c3e02598#gistcomment-2899014
const paginationRange = (currentPage, pageCount, delta = 1) => {
  const separate = (a, b) => [a, ...({
    0: [],
    1: [b],
    // 2: [a + 1, b],
  }[b - a] || ['hidden', b])];

  return Array(delta * 2 + 1)
    .fill()
    .map((_, index) => currentPage - delta + index)
    .filter((page) => page > 0 && page <= pageCount)
    .flatMap((page, index, { length }) => {
      if (!index) return separate(1, page);
      if (index === length - 1) return separate(page, pageCount);
      return [page];
    });
};

const PaginationBtns = ({ count, paginate, currentPage }) => {
  const shouldShowPrevBtn = currentPage > 1;
  const shouldShowNextBtn = currentPage < count;
  return (
    <PaginationWrapper>
      {shouldShowPrevBtn && (
        <PaginationBtnPrevNext
          onClick={() => { paginate('prev'); }}
          role="button"
        >
          <Icon name="s-arrow-left" color="white" />
        </PaginationBtnPrevNext>
      )}
      {paginationRange(currentPage, count).map((page, i) => {
        if (page === 'hidden') {
          return <Dots key={i}>…</Dots>;
        }
        return (
          <PaginationBtn
            onClick={() => {
              paginate(page);
            }}
            key={i}
            currentPage={currentPage === page}
            role="button"
          >
            {page}
          </PaginationBtn>
        );
      })}
      {shouldShowNextBtn && (
        <PaginationBtnPrevNext
          onClick={() => { paginate('next'); }}
          role="button"
        >
          <Icon name="s-arrow-right" color="white" />
        </PaginationBtnPrevNext>
      )}
    </PaginationWrapper>
  );
};

PaginationBtns.propTypes = {
  count: PropTypes.number.isRequired,
  paginate: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};
/*
 to be used as follows
 const { Pagination, increment } = usePagination(PaginatedReview, REVIEW_QUERY, {
    variables: {
      limit: 10,
      restaurantId: restaurant.foreignId,
    }
  });
*
*/
const usePagination = (PaginatedComponent, query, perPage = 20, opts = {}) => {
  const { variables } = opts;
  const offset = perPage;
  variables.limit = perPage;

  const [paginatedState, setPagination] = useState({
    count: 0,
    total: null,
    totalPages: null,
    currentPage: 1,
  });
  variables.offset = paginatedState.count;
  const { loading, error, data } = useQuery(query, {
    variables,
    fetchPolicy: 'network-only',
    ssr: false,
  });

  const scrollToTop = () => {
    scroller.scrollTo('RestaurantReviews', {
      duration: 900,
      smooth: 'easeInOutCubic',
      isDynamic: true,
    });
  };

  // inits our pagination state
  const init = (total) => setPagination((p) => {
    const search = queryString.parse(window.location.search);
    const totalPages = Math.ceil(total / perPage);
    return {
      ...p,
      total,
      totalPages,
      currentPage: parseInt(search.page, 10) || p.currentPage,
    };
  });

  const paginate = (page) => setPagination((p) => {
    let { count } = p;
    let { currentPage } = p;
    if (page === 'next') {
      count += offset;
      currentPage += 1;
    } else if (page === 'prev') {
      count -= offset;
      currentPage -= 1;
    } else {
      count = (page - 1) * offset;
      currentPage = page;
    }
    return {
      ...p,
      count,
      currentPage,
    };
  });

  useEffect(() => {
    if (paginatedState.count > 0) {
      scrollToTop();
    }
  }, [paginatedState.currentPage]);

  useEffect(() => {
    if (paginatedState.total === null && data) {
      init(data.data.pageInfo.totalCount);
    }
  }, [data]);

  return () => {
    if (loading) {
      return (
        <>
          {
            range(0, 9).map((i) => (
              <Box
                mb={60}
                display={[null, 'flex']}
                className="pulse-animation"
                key={`${i}_loading`}
              >
                <Box mb="18px" mr="24px" width="56px" height="56px" bg="grayscale.300" borderRadius="50%" />
                <Box flex={1}>
                  <Box height={15} mb={20} maxWidth={170} bg="grayscale.300" />
                  <Box height={10} mb={25} maxWidth={150} bg="grayscale.300" />
                  <Box height={20} mb={25} maxWidth="90%" bg="grayscale.300" />
                  <Box height={10} maxWidth="50%" bg="grayscale.300" />
                </Box>
              </Box>
            ))
          }
        </>
      );
    }
    if (error || !data) {
      return 'No reviews';
    }
    const { data: reviewData } = data;

    return (
      <>
        {reviewData.edges.map(({ node }) => (
          <PaginatedComponent data={node} key={node.id} />
        ))}
        {paginatedState.totalPages > 1 && (
          <PaginationBtns
            paginate={paginate}
            currentPage={paginatedState.currentPage}
            count={paginatedState.totalPages}
          />
        )}
      </>
    );
  };
};

export default usePagination;
