import { useState, useEffect } from 'react';
import getFirebase from '../helpers/firebase';

const useFirebase = () => {
  const [loaded, setLoaded] = useState(false);
  const [firebaseData, setFirebase] = useState(false);

  const initialize = () => {
    if (!firebaseData && typeof window !== 'undefined') {
      const app = import('firebase/app');
      const database = import('firebase/database');
      const rc = import('firebase/remote-config');

      Promise.all([app, rc, database]).then(([firebase]) => {
        setFirebase(getFirebase(firebase));
        setLoaded(true);
      });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return { firebaseIsLoaded: loaded, firebaseData };
};

export default useFirebase;
