// @ts-ignore
import jwtDecode from 'jwt-decode';
import { isEmpty } from 'lodash';
import { User } from '@firsttable/types';
import { isBrowser } from './functions';

const getUser = (): any => {
  if (typeof window !== 'undefined') {
    const user = localStorage.getItem('user') || '';
    return user.length > 0 ? JSON.parse(user) : {};
  }
  return {};
};

const getUserAndTokenFromStorage = (): { user: User | null; token: string | null } => {
  if (isBrowser) {
    const user = window.localStorage.getItem('user') || '';
    return user.length > 0 ? JSON.parse(user) : { user: null, token: null };
  }
  return { user: null, token: null };
};

/**
 * Checks if a permission exist for a user. Can pass a single string or array of Perms
 * @param permission
 * @returns {boolean|*}
 */
const hasPermission = (permission: string|Array<string>) => {
  const user = getUser();
  if (isEmpty(user) || !user.token) {
    return false;
  }
  const decodedJWT: any = jwtDecode(user.token);
  const userData = JSON.parse(decodedJWT.sub);
  if (typeof permission === 'string') {
    return userData.permissionsList && userData.permissionsList.includes(permission);
  }
  // check array list of permissions exist for user
  return permission.filter((perm) => userData.permissionsList.includes(perm)).length > 0;
};

const howManyDaysOfAvailabilityToShow = () => (hasPermission('ADMIN') || hasPermission('ADVANCED_BOOKINGS') ? 8 : 7);

export {
  hasPermission,
  getUser,
  getUserAndTokenFromStorage,
  howManyDaysOfAvailabilityToShow,
};
