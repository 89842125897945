import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import { width, height } from 'styled-system';
import Box from '../../atoms/Box';
import Icon from '../../atoms/Icon';

const thumbnailsHeight = 100;
const spaceBetweenThumbs = 10;
const bg = 'rgba(39, 43, 46, 0.5)';

const Button = styled.button`
  .pswp & {
    background: ${bg};
  }

  .svg-icon {
    pointer-events: none;
  }
`;

const ButtonClose = styled(Box)`
  .pswp & {
    background: ${bg};
    ${width};
    ${height};
  }
  
  .svg-icon {
    pointer-events: none;
  }
`;

const Thumbnails = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${thumbnailsHeight}px;
  background: ${bg};
`;

const Thumbnail = styled.div`
  overflow: hidden;
  margin-top: ${spaceBetweenThumbs}px;
  cursor: pointer;
  user-select: none;
  background: #000;

  &.swiper-slide {
    height: ${thumbnailsHeight - spaceBetweenThumbs * 2}px;
    width: ${thumbnailsHeight - spaceBetweenThumbs * 2}px;
  }
  
  &::before {
    z-index: 1;
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.1s ease;
    background: ${bg};
  }

  &:hover::before,
  &.swiper-slide-active::before {
    background: none;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Markup = ({
  items, index, goTo,
}) => {
  const [swiper, updateSwiper] = useState(null);
  const swiperProps = {
    simulateTouch: false,
    shouldSwiperUpdate: true,
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: spaceBetweenThumbs,
    getSwiper: (ins) => updateSwiper(ins),
  };

  if (swiper) {
    swiper.slideTo(index);
  }

  return (
    <>
      <Box className="pswp__bg" bg="black" />
      <div className="pswp__scroll-wrap">
        <div className="pswp__container">
          <div className="pswp__item" />
          <div className="pswp__item" />
          <div className="pswp__item" />
        </div>
        <div className="pswp__ui pswp__ui--hidden">
          <div className="pswp__top-bar">
            <Box
              className="pswp__counter"
              width={['75px', null, '100px']}
              height={['75px', null, '100px']}
            />
            <ButtonClose
              as="button"
              width={['75px', null, '100px']}
              height={['75px', null, '100px']}
              type="button"
              className="pswp__button pswp__button--close"
            >
              <Icon name="nav-close" color="white" className="svg-icon" />
            </ButtonClose>
            <div className="pswp__preloader">
              <div className="pswp__preloader__icn">
                <div className="pswp__preloader__cut">
                  <div className="pswp__preloader__donut" />
                </div>
              </div>
            </div>
          </div>
          <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
            <div className="pswp__share-tooltip" />
          </div>
          <Box display={['none', null, 'block']}>
            <Button
              type="button"
              className="pswp__button pswp__button--arrow--left"
            >
              <Icon name="nav-arrow-left" color="white" className="svg-icon" />
            </Button>
            <Button
              type="button"
              className="pswp__button pswp__button--arrow--right"
            >
              <Icon name="nav-arrow-right" color="white" className="svg-icon" />
            </Button>
          </Box>
          <div className="pswp__caption">
            <div className="pswp__caption__center" />
          </div>
        </div>
      </div>
      <Thumbnails>
        <Swiper {...swiperProps}>
          {items.map(({ src }, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Thumbnail key={i} onClick={() => goTo(i)}>
              <img src={src} alt="" />
            </Thumbnail>
          ))}
        </Swiper>
      </Thumbnails>
    </>
  );
};

Markup.propTypes = {
  items: PropTypes.array,
  index: PropTypes.number,
  goTo: PropTypes.func,
};

Markup.defaultProps = {
  index: 0,
};

export default Markup;
