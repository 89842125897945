import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import { isMobile } from 'react-device-detect';
import {
  Box, Icon, Container, Row, Col,
} from '@firsttable/web-components/atoms';
import { awsImage } from '@firsttable/functions';

import transImg from './images/1px.gif';

const GalleryImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  max-width: 100%;
  max-height: 100%;
`;

const SwiperArrow = styled(Box)`
  position: absolute;
  z-index: 1;
  top: 50%;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  outline: none;
`;

const largeGallery = {
  containerModifierClass: 'restaurant-gallery ',
  spaceBetween: 10,
  speed: 0,
  controller: {},
  simulateTouch: false,
  keyboard: true,
  preloadImages: false,
  lazy: {
    loadPrevNext: true,
  },
  navigation: {
    prevEl: '.swiper-button-prev',
    nextEl: '.swiper-button-next',
  },
  breakpoints: {
    992: {
      speed: 300,
    },
  },
  renderPrevButton: () => (
    <SwiperArrow left="20px" role="button" className="swiper-button-prev custom" mt="-56px" display={['none', 'flex']}>
      <Icon name="nav-arrow-left" color="white" />
    </SwiperArrow>
  ),
  renderNextButton: () => (
    <SwiperArrow right="20px" role="button" className="swiper-button-next custom" mt="-43px" display={['none', 'flex']}>
      <Icon name="nav-arrow-right" color="white" />
    </SwiperArrow>
  ),
};

const Center = ({ children }) => (
  <Container>
    <Row>
      <Col
        textAlign="center"
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        color="white"
      >
        <div>
          {children}
        </div>
      </Col>
    </Row>
  </Container>
);
Center.propTypes = {
  children: PropTypes.node,
};

const SwiperGalleryView = ({ restaurantImages, index }) => {
  let width = 1080;
  let height = 1080;
  if (isMobile) {
    width = 768;
    height = 475;
  }
  return (
    <Swiper {...largeGallery} initialSlide={index}>
      {restaurantImages && restaurantImages.map(({ node }) => (
        <div className="swiper-slide" key={node.id}>
          <Box height="100vh">
            <GalleryImage
              src={transImg}
              srcSet={`${awsImage(node.url, width, height)} 1x, ${awsImage(node.url, width * 2, height * 2)} 2x`}
              className="swiper-lazy"
              alt=""
            />
            <div className="swiper-lazy-preloader" />
          </Box>
        </div>
      ))}
    </Swiper>
  );
};

SwiperGalleryView.propTypes = {
  index: PropTypes.number,
  restaurantImages: PropTypes.array,
};

SwiperGalleryView.defaultProps = {
  index: 0,
  restaurantImages: null,
};

export default SwiperGalleryView;
