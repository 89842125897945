import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Title,
  Box,
  Icon,
  Link,
  Divider,
  Text,
  Extender,
} from '@firsttable/web-components/atoms';
import spaces from '@firsttable/web-theme/styledSpaces';
import BodyTitle from '@firsttable/web-components/atoms/BodyTitle';
import { decodeEntities } from '@firsttable/functions';
import styled from 'styled-components';
import ModalContext from '../../../context/ModalContext';
import SampleMenu from './SampleMenu';
import Amex from '../../Amex';
import parseHtml from '../../../helpers/parseHtml';

const OpenHours = styled(Box)`
  white-space: pre-wrap;
`;

const StyledIcon = (props) => (
  <Icon mr="10px" top="-1px" color="gold.900" {...props} />
);

const StyledDivider = () => (
  <Divider
    top="-5px"
    left="-2px"
    style={{ transform: 'rotate(-67.69deg)' }}
    bg="black"
    height="1px"
    width="29px"
    display={['none', 'inline-block']}
    ml="7px"
  />
);

const RestaurantInfo = ({ restaurant, ...props }) => {
  const { showModalFunc } = useContext(ModalContext);
  const [toggleMenuState, setToggleMenuState] = useState(false);
  const [toggleContactsState, setToggleContactsState] = useState(false);
  const [clippedInfo, setClippedInfo] = useState(true);
  const { cuisines, allMenuImages } = restaurant;
  const toggleMenu = () => setToggleMenuState(!toggleMenuState);
  const toggleContacts = () => setToggleContactsState(!toggleContactsState);
  const toggleInfo = () => setClippedInfo(!clippedInfo);
  const hasMenuImages = allMenuImages?.edges.filter(({ node }) =>
    Array.isArray(node?.menuImages?.edges),
  );
  const escapedContent = [];
  restaurant.content
    .split('\n')
    .map((para) => escapedContent.push(decodeEntities(para)));

  return (
    <Box {...spaces(props)} mb={['20px', '30px']} pb="1px">
      <Title mb="25px">Overview</Title>
      <Box mb="15px" display={['block', 'flex']} flexWrap="wrap">
        <Box mb="xs">
          <StyledIcon name="m-spoon" />
          {cuisines.edges.map(({ node }, index) =>
            index !== cuisines.edges.length - 1
              ? `${node.label || node.cuisineTitle}, `
              : node.label || node.cuisineTitle,
          )}
          <StyledDivider />
        </Box>
        <Box mb="xs">
          <StyledIcon name="m-map" />
          <Link
            onClick={() =>
              showModalFunc('restaurantMap', {
                type: 'basic',
                data: restaurant,
              })
            }
            underline
          >
            How to Find Us
          </Link>
          <StyledDivider />
        </Box>
        {hasMenuImages.length > 0 && (
          <Box mb="xs">
            <StyledIcon name="m-book" />
            <Link onClick={toggleMenu} underline={!toggleMenuState}>
              Sample Menu
            </Link>
            <StyledDivider />
          </Box>
        )}
        <Box mb="xs">
          <StyledIcon name="m-contacts" />
          <Link underline={!toggleContactsState} onClick={toggleContacts}>
            Contacts
          </Link>
          {restaurant.acceptsAmex && <StyledDivider />}
        </Box>
        {restaurant.acceptsAmex && (
          <Box mb="xs">
            <StyledIcon name="payment" />
            <Amex />
          </Box>
        )}
      </Box>
      <SampleMenu
        menuImages={allMenuImages}
        showed={toggleMenuState.toString()}
      />
      <Box
        display={toggleContactsState ? 'flex' : 'none'}
        mb={20}
        pb={20}
        flexWrap="wrap"
        borderBottom="1px solid"
        borderColor="grayscale.300"
      >
        <Box width={['100%', 1 / 3.33]} mr="5%" pb={[10, 0]}>
          <BodyTitle size="xs" mb="xs">
            Address:
          </BodyTitle>
          {restaurant.street}
          <br />
          {restaurant.city} {restaurant.zip}
        </Box>
        <Box width={['100%', 1 / 3.33]} pb={[10, 0]}>
          <BodyTitle size="xs" mb="xs">
            Phone:
          </BodyTitle>
          {restaurant.phone}
        </Box>
        {restaurant.website && (
          <Box width={['100%', 1 / 3.33]} pb={[10, 0]}>
            <BodyTitle size="xs" mb="xs">
              Website:
            </BodyTitle>
            <Link href={restaurant.website} underline>
              Click here to view
            </Link>
          </Box>
        )}
        {restaurant.openHours && (
          <Box width="100%" mt={[0, 10]}>
            <BodyTitle size="xs" mb="xs">
              Open hours:
            </BodyTitle>
            <OpenHours>{restaurant.openHours}</OpenHours>
          </Box>
        )}
      </Box>
      <Box
        maxHeight={clippedInfo ? 310 : null}
        overflow={clippedInfo ? 'hidden' : null}
        css="p:last-child { margin-bottom: 0; }"
      >
        {escapedContent.map((para, i) => (
          <Text key={i}>
            {para.match(/<[^>]*>?/gm)
              ? // eslint-disable-next-line react/no-danger
                parseHtml(para)
              : para}
          </Text>
        ))}
      </Box>
      <Extender
        m="20px 0 0"
        gradient
        isExpanded={!clippedInfo}
        onClick={toggleInfo}
      />
    </Box>
  );
};

RestaurantInfo.propTypes = {
  restaurant: PropTypes.object,
};
RestaurantInfo.defaultProps = {
  restaurant: {},
};

export default RestaurantInfo;
