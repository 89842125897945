import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Photoswipe from '@firsttable/web-components/organisms/Photoswipe';
import Box from '@firsttable/web-components/atoms/Box';
import { awsImage, compareValues } from '@firsttable/functions';

const Menubox = styled(Box)`
  width: 80px;
`;

// const menu1 = [
//   { src: menu1img1, w: 1070, h: 1500 },
//   { src: menu1img2, w: 1070, h: 1500 },
//   { src: menu1img3, w: 1070, h: 1500 },
//   { src: menu1img4, w: 1070, h: 1500 },
//   { src: menu1img5, w: 1070, h: 1500 },
//   { src: menu1img6, w: 1070, h: 1500 },
//   { src: menu1img7, w: 1070, h: 1500 },
//   { src: menu1img8, w: 1070, h: 1500 },
// ];
//
// const menu2 = [
//   { src: menu2img1, w: 1070, h: 1500 },
//   { src: menu2img2, w: 1070, h: 1500 },
//   { src: menu2img3, w: 1070, h: 1500 },
//   { src: menu2img4, w: 1070, h: 1500 },
//   { src: menu2img5, w: 1070, h: 1500 },
//   { src: menu2img6, w: 1070, h: 1500 },
//   { src: menu2img7, w: 1070, h: 1500 },
//   { src: menu2img8, w: 1070, h: 1500 },

/**
 * Get the size of the menu image otherwise applies defaults
 * todo resize large images proportionality within a max width or height dependant on orientation
 * @param menuImg
 * @returns {{orientation: string, src: string, w: (*|number), h: (*|number), page: *, url: *}}
 */
const formatMenuImage = (menuImg) => {
  const { originalWidth, originalHeight, url } = menuImg;
  const w = originalWidth || 1500;
  const h = originalHeight || 1070;

  // A5 paper
  let designerWidth = 2480;
  let designerHeight = 1748;

  const originalRatio = w / h;
  const designerRatio = designerWidth / designerHeight;

  let orientation = 'even';
  if (w > h) {
    orientation = 'landscape';
  } else if (w < h) {
    orientation = 'portrait';
  }

  if (originalRatio > designerRatio) {
    designerHeight = Math.round(designerWidth / originalRatio);
  } else {
    designerWidth = Math.round(designerHeight * originalRatio);
  }

  return {
    src: awsImage(url, designerWidth, designerHeight),
    url,
    w,
    h,
    orientation,
    page: menuImg.page,
  };
};

/**
 * todo this looks like it be refactored better
 * @param menuImages
 * @returns {[]}
 */
const getMenuPages = (menuImages) => {
  const pages = [];
  const noPages = [];
  menuImages.edges.forEach(({ node }) => {
    if (
      !(
        !node.menuImages ||
        !node.menuImages.edges.length ||
        !menuImages.edges[0].node.menuImages ||
        !menuImages.edges[0].node.menuImages.edges.length
      )
    ) {
      const menus = node.menuImages.edges;
      if (node.extension === 'pdf') {
        pages.push(menus.map(({ node: menuImg }) => formatMenuImage(menuImg)));
      } else {
        const menuImg = menus[0].node;
        noPages.push(formatMenuImage(menuImg));
      }
    }
  });
  // merge pages
  if (noPages.length) {
    pages.push(noPages);
  }
  // ensure pages are in correct order.
  if (pages.length > 0) {
    pages[0].sort(compareValues('page'));
  }

  return pages;
};

class SampleMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      index: 0,
      menu: [],
    };
  }

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  handleOpen = (menu) => {
    this.setState({
      isOpen: true,
      menu,
    });
  };

  handleChange = (index) => {
    this.setState({
      index,
    });
  };

  render() {
    const { showed, menuImages } = this.props;
    const { isOpen, index, menu } = this.state;
    const pages = getMenuPages(menuImages);

    return (
      <Box
        display={showed !== 'true' ? 'none' : 'flex'}
        mb="30px"
        borderBottom="1px solid"
        borderColor="grayscale.300"
      >
        {pages.map((node, i) => (
          <Menubox
            key={i}
            m="0 30px 30px 0"
            role="button"
            onClick={() => this.handleOpen([...node])}
          >
            <img
              src={awsImage(node[0].url, 480, 600)}
              alt=""
              className="img-fluid"
            />
          </Menubox>
        ))}

        <Photoswipe
          items={menu}
          isOpen={isOpen}
          index={index}
          onClose={this.handleClose}
          onChange={this.handleChange}
        />
      </Box>
    );
  }
}

SampleMenu.propTypes = {
  showed: PropTypes.string,
  menuImages: PropTypes.shape(),
};
SampleMenu.defaultProps = {};

export default SampleMenu;
