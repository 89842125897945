import React from 'react';
import globalHook from 'use-global-hook';
import Logger from '../helpers/logger';

const logger = new Logger('Alert State');

const initialState = {
  alertFired: false,
};

const actions = {
  setAlertState: (store, alertFired) => {
    store.setState({ alertFired });
  },
};

const useGlobal = globalHook(React, initialState, actions);

const useAlertState = () => {
  const [alertFired, setAlertState] = useGlobal();
  logger.debug('Alert state', alertFired.alertFired);
  return {
    ...alertFired,
    ...setAlertState,
  };
};

export default useAlertState;
