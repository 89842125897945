import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { isEmpty } from 'lodash';
import { awsImage } from '@firsttable/functions';
import Box from '../../atoms/Box';
import Flex from '../../atoms/Flex';
import {
  Wrapper,
  ImgOverlay,
  ImgLength,
  ThumbnailImage,
  ImgBox,
  MobileImageWrap,
} from './styles';

const PureRestaurantHero = ({
  restaurantImages,
  handleRestaurantLightBox,
  facebookVideoURL,
  featuredImage,
  loading,
}) => {
  const imgHeight = 400;
  const [heroImages, setHeroImages] = useState([]);

  const getImg = (num, mobile = false) => {
    if (!heroImages[num] || loading) {
      return {};
    }

    const { url } = heroImages[num];
    const { length } = heroImages;

    let width = 0;
    let height = imgHeight;

    if (length === 1) {
      width = 1266;
    }

    if (length === 2) {
      width = 1266 / 2;
    }

    if (length > 2) {
      switch (num) {
        case 0:
          width = 646;
          break;
        case 1:
          width = 400;
          if (length > 3) {
            height = imgHeight / 2;
          }
          break;
        case 3:
          if (length > 3) {
            width = 400;
            height = imgHeight / 2;
          }
          break;
        default:
          width = 220;
          break;
      }
    }

    if (mobile) {
      width = 768;
      height = 475;
    }
    return {
      width,
      height,
      src: awsImage(url, width, height),
      srcset: `${awsImage(url, width, height)} 1x, ${awsImage(
        url,
        width * 2,
        height * 2
      )} 2x`,
    };
  };
  useEffect(() => {
    const images = [];
    if (featuredImage && featuredImage.url) {
      images.push(featuredImage);
    }
    setHeroImages([...images, ...restaurantImages.map(({ node }) => node)]);
  }, [featuredImage, restaurantImages]);

  return (
    <Wrapper>
      <Box display={['block', 'flex']} className="bg-pulse-animation">
        <ImgBox
          height={[null, imgHeight]}
          onClick={() => handleRestaurantLightBox(0)}
        >
          <MobileImageWrap display={[null, 'none']}>
            <LazyLoadImage
              wrapperClassName="lazyload-img-wrapper"
              srcSet={getImg(0, true).srcSet}
              src={getImg(0, true).src}
              effect="opacity"
              width="100%"
            />
          </MobileImageWrap>
          <Box
            display={['none', 'inline']}
            width={getImg(0).width}
            height={getImg(0).height}
          >
            {facebookVideoURL ? (
              <iframe
                src={`https://www.facebook.com/plugins/video.php?href=${facebookVideoURL}&show_text=0&width=560&autoplay=true&loop=1`}
                width="720"
                height="400"
                title="Facebook Video"
                style={{ border: 'none', overflow: 'hidden' }}
                scrolling="no"
                frameBorder="0"
                allowFullScreen
              />
            ) : (
              <LazyLoadImage
                srcSet={getImg(0).srcset}
                src={getImg(0).src}
                width={getImg(0).width}
                height={getImg(0).height}
                effect="opacity"
              />
            )}
          </Box>
        </ImgBox>
        {!isEmpty(getImg(1)) && (
          <ImgBox
            display={['none', 'block']}
            onClick={() => handleRestaurantLightBox(1)}
          >
            <Box width={getImg(1).width} height={getImg(1).height}>
              <LazyLoadImage
                srcSet={getImg(1).srcset}
                src={getImg(1).src}
                effect="opacity"
              />
            </Box>
            {!isEmpty(getImg(3)) && (
              <Box width={getImg(3).width} height={getImg(3).height}>
                <LazyLoadImage
                  srcSet={getImg(3).srcset}
                  src={getImg(3).src}
                  effect="opacity"
                />
              </Box>
            )}
          </ImgBox>
        )}
        {!isEmpty(getImg(2)) && (
          <ImgBox
            display={['none', 'block']}
            onClick={() => handleRestaurantLightBox(2)}
          >
            <Box width={getImg(2).width} height={getImg(2).height}>
              <LazyLoadImage
                srcSet={getImg(2).srcset}
                src={getImg(2).src}
                effect="opacity"
              />
            </Box>
          </ImgBox>
        )}
      </Box>
      <Flex
        width="100%"
        display="inline-flex"
        m={['8px 0 0', '-47px 0 0', null, '-47px 0 0 53px']}
      >
        {heroImages.slice(0, 5).map((node, index) => (
          <ThumbnailImage
            position="relative"
            width="100%"
            maxWidth="97px"
            mr="xs"
            key={node.id}
            role="button"
            onClick={() => handleRestaurantLightBox(index)}
            boxShadow="0 0 0 8px white"
          >
            <LazyLoadImage
              wrapperClassName="lazyload-img-wrapper"
              srcSet={`${awsImage(node.url, 97, 80)} 1x, ${awsImage(
                node.url,
                194,
                160
              )} 2x`}
              src={awsImage(node.url, 97, 80)}
              effect="opacity"
              className="img-fluid"
            />
            {index === 4 && (
              <>
                <ImgOverlay />
                <ImgLength fontSize={[null, 'heading.h5']}>
                  +{heroImages.length}
                </ImgLength>
              </>
            )}
          </ThumbnailImage>
        ))}
      </Flex>
    </Wrapper>
  );
};

PureRestaurantHero.propTypes = {
  restaurantImages: PropTypes.array,
  handleRestaurantLightBox: PropTypes.func.isRequired,
  facebookVideoURL: PropTypes.string,
  featuredImage: PropTypes.any,
  loading: PropTypes.bool,
};
PureRestaurantHero.defaultProps = {
  restaurantImages: [],
  featuredImage: null,
  facebookVideoURL: null,
  loading: false,
};

export default PureRestaurantHero;
