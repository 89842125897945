import { scroller } from 'react-scroll';
import { howManyDaysOfAvailabilityToShow } from './user';

export const isBrowser = (() => typeof window !== 'undefined')();

const slugify = (string: string): string => {
  const a =
    'àáäâãåăæąçćčđèéėëêęǵḧìíïîįłḿǹńňñòóöôœøṕŕřßśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
  const b =
    'aaaaaaaaacccdeeeeeeghiiiiilmnnnnooooooprrssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return (
    string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      // eslint-disable-next-line no-useless-escape
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      // eslint-disable-next-line no-useless-escape
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '')
  ); // Trim - from end of text
};

const capitalise = (s: string): string =>
  s.charAt(0).toUpperCase() + s.slice(1);

const isFirstCharacterAVowel = (c: string) =>
  ['a', 'e', 'i', 'o', 'u'].indexOf(c.toLowerCase().charAt(0)) !== -1;

const getUsersPosition = (options = {}) =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });

/**
 * range()
 * @see https://gist.githubusercontent.com/gladchinda/439981b34aa8f23c661e9663edf762f0/raw/96a4f5f25539ae9a871264f073e849580c160b31/range-function.js
 *
 * Returns an array of numbers between a start number and an end number incremented
 * sequentially by a fixed number(step), beginning with either the start number or
 * the end number depending on which is greater.
 *
 * @param {number} start (Required: The start number.)
 * @param {number} end (Required: The end number. If end is less than start,
 *  then the range begins with end instead of start and decrements instead of increment.)
 * @param {number} step (Optional: The fixed increment or decrement step. Defaults to 1.)
 *
 * @return {array} (An array containing the range numbers.)
 *
 * @throws {TypeError} (If any of start, end and step is not a finite number.)
 * @throws {Error} (If step is not a positive number.)
 */
const range = (start: number, end: number, step: number = 1) => {
  // Test that the first 3 arguments are finite numbers.
  // Using Array.prototype.every() and Number.isFinite().
  const allNumbers = [start, end, step].every(Number.isFinite);

  // Throw an error if any of the first 3 arguments is not a finite number.
  if (!allNumbers) {
    throw new TypeError('range() expects only finite numbers as arguments.');
  }

  // Ensure the step is always a positive number.
  if (step <= 0) {
    throw new Error('step must be a number greater than 0.');
  }

  // When the start number is greater than the end number,
  // modify the step for decrementing instead of incrementing.
  if (start > end) {
    // eslint-disable-next-line no-param-reassign
    step = -step;
  }

  // Determine the length of the array to be returned.
  // The length is incremented by 1 after Math.floor().
  // This ensures that the end number is listed if it falls within the range.
  const length = Math.floor(Math.abs((end - start) / step)) + 1;

  // Fill up a new array with the range numbers
  // using Array.from() with a mapping function.
  // Finally, return the new array.
  // @ts-ignore
  return Array.from(Array(length), (x, index) => start + index * step);
};

const numericToWord = (value: number): string => {
  const input = String(value).split('');
  const mapData: any = {
    0: 'zero',
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    6: 'six',
    7: 'seven',
    8: 'eight',
    9: 'nine',
  };
  const tempArray = [];
  for (let i = 0; i < input.length; i += 1) {
    tempArray.push(mapData[input[i]]);
  }
  return tempArray.join(' ');
};

/**
 * From https://stackoverflow.com/questions/44195322/a-plain-javascript-way-to-decode-html-entities-works-on-both-browsers-and-node
 * @param encodedString
 * @returns {*}
 */
const decodeEntities = (encodedString: string): string => {
  const translateRe = /&(nbsp|amp|quot|lt|gt);/g;
  const translate: any = {
    nbsp: ' ',
    amp: '&',
    quot: '"',
    lt: '<',
    gt: '>',
  };
  // @ts-ignore
  return encodedString
    .replace(translateRe, (match, entity) => translate[entity])
    .replace(/&#(\d+);/gi, (match, numStr) => {
      const num = parseInt(numStr, 10);
      return String.fromCharCode(num);
    });
};

// @ts-ignore
const scrollTo = (anchor: any, offset: number = 0, duration: number = 900) => {
  scroller.scrollTo(anchor, {
    duration,
    smooth: 'easeInOutCubic',
    isDynamic: true,
    offset,
  });
};

const compareValues =
  (key: any, order: string = 'asc') =>
  (a: any, b: any) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = a[key];
    const varB = b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };

const numberOfDaysToShow = howManyDaysOfAvailabilityToShow();

// handle 9pm availability
const availabilityDaysToShow = (availability: any, siteId?: number): any => {
  const currenthourOfDay = new Date().getHours();
  const currentMinutes = new Date().getMinutes();
  const minuteCutOff = 0; // 0
  const hourCutOff = 21; // 9pm
  let daysToShow = Object.keys(availability).slice(0, numberOfDaysToShow);
  if (
    siteId &&
    [1].indexOf(siteId) !== -1 && // check it's in the right region e.g. Queenstown
    currenthourOfDay >= hourCutOff && // check the current hour is greater than or equal to the roll over time
    currentMinutes >= minuteCutOff && // check the current minute is greater than or equal to the roll over time
    Object.keys(availability).length > 7 // we have the length to slice
  ) {
    // slice the first day off
    daysToShow = Object.keys(availability).slice(1, numberOfDaysToShow + 1);
  }
  return daysToShow;
};

const httpsFormattedURL = (u: string): string => {
  let s = u;
  if (!s.match(/^[a-zA-Z]+:\/\//)) {
    s = `https://${s}`;
  }
  return s;
};

const formatUrlTitle = (url: string) =>
  url.replace(/https:\/\/|http:\/\/|www./gi, '');

const formatUrl = (url: string) => {
  let formattedUrl = url;
  if (!/^https?:\/\//i.test(formattedUrl)) {
    formattedUrl = `http://${formattedUrl}`;
  }
  return formattedUrl;
};

const formatInstagramUrl = (url: string) => {
  let newUrl = url.replace('@', 'https://www.instagram.com/');

  if (newUrl.indexOf('instagram.com') === -1) {
    newUrl = `https://www.instagram.com/${newUrl}`;
  }

  return formatUrl(newUrl);
};

const formatFacebookUrl = (url: string) => {
  let newUrl = url.replace('@', 'https://www.facebook.com/');

  if (newUrl.indexOf('facebook.com') === -1) {
    newUrl = `https://www.facebook.com/${newUrl}`;
  }

  return formatUrl(newUrl);
};

/**
 * Function to group array of objects into subsets
 * Takes a function to resolve the key.
 * Callback function can return a string, or an array of key strings if you want the object to appear in more than one group
 * e.g groupBy(jsonObj, (i)=>i.age > 17 ? 'adult' : 'child'); or groupBy(posts, (i)=>i.author.split(','));
 *
 * @param array original list of objects
 * @param func resolver function, should return string or array of string for grouping key
 * @returns returns an object (key:array) of grouped items
 */
const groupBy = (
  array: Object[],
  func: (item: any) => string | string[]
): any =>
  [...array].reduce((groups: { [key: string]: Object[] }, item) => {
    const groupId = func(item); // get grouping key(s)
    const loop = Array.isArray(groupId) ? groupId : [groupId]; // always run keys as an array
    loop.forEach((name: string) => {
      const group = groups[name] || []; // find or create group
      group.push(item); // add item to group
      groups[name] = group; // push in altered group
    });
    return groups;
  }, {});

export {
  slugify,
  availabilityDaysToShow,
  compareValues,
  range,
  getUsersPosition,
  numberOfDaysToShow,
  scrollTo,
  decodeEntities,
  numericToWord,
  capitalise,
  isFirstCharacterAVowel,
  httpsFormattedURL,
  formatUrlTitle,
  formatUrl,
  formatInstagramUrl,
  formatFacebookUrl,
  groupBy,
};
