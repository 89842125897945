import React, { useContext, useEffect, useRef, useState } from 'react';
import ModalContext from '../context/ModalContext';
import SwiperGallery from '../components/organisms/Swiper/LightBoxGallery';

const useRestaurantGalleryLightBox = (restaurant) => {
  const [lightBoxState, setLightBoxState] = useState(false);
  const [lightBoxIndex, setLightBoxIndex] = useState(0);
  const firstUpdate = useRef(true);
  const { showModalFunc, hideModalFunc, isModalOpen } = useContext(
    ModalContext,
  );

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!isModalOpen && restaurant?.allImages?.edges.length > 0) {
      showModalFunc(
        <SwiperGallery
          restaurantImages={restaurant.allImages.edges}
          index={lightBoxIndex}
        />,
        { type: 'galleryLightBox' },
      );
    }
    if (!lightBoxState) {
      hideModalFunc();
    }
  }, [lightBoxState]);

  // set lightbox state to false when modal is closed
  useEffect(() => {
    if (!isModalOpen) {
      setLightBoxState(isModalOpen);
    }
  }, [isModalOpen]);

  const showRestaurantLightBox = (index) => {
    setLightBoxState(!lightBoxState);
    setLightBoxIndex(index);
  };

  return {
    showRestaurantLightBox,
  };
};

export default useRestaurantGalleryLightBox;
